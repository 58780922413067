<template>
  <div>
    <section class="trbaner">
      <div class="foxj">
        <div class="am-container">
          <div class="txt">
            <div class="am-vertical-align-middle">
              <p class="tit">中海创水务科技</p>
              <p class="note">
                中海创水务科技是中海创科技集团旗下专注于水务信息化的行业企业。中海创水务科技集软硬件开发、设计、建设、技术服务及运维托管为一体的行业综合运营服务商。
              </p>
            </div>
          </div>
        </div>
      </div>
      <img src="../assets/banner/banner6.png" />
    </section>
    <section class="about-jj">
      <div class="am-container">
        <div class="douhao">
          <img src="~images/yh.png" />
        </div>
        <div class="abjj-txt">
          <div class="abjj-left">
            <p class="tit">公司简介</p>
            <p class="titl">中海创科技集团简介</p>
            <p class="note">{{ this.$parent.data.group_blurb }}</p>
            <p class="titl">中海创水务科技（福建）有限公司简介</p>
            <p class="note">{{ this.$parent.data.company_blurb }}</p>
          </div>
          <div class="abjj-right">
            <img src="../assets/img/p6.png" />
          </div>
        </div>
      </div>
    </section>
    <section class="hxjs">
      <div class="am-container">
        <div class="apptit">
          <p class="entit">CORE TECHNOLOGY</p>
          <p class="tit">核心技术</p>
        </div>
        <div>
          <div class="note">
            IAP OS：自主可控的工业互联网操作系统，跨平台的工业控制体系，IAP OS
            2000年--1.0版，2019年--4.0版，成功应用于华能集团、紫金矿业、码头港口、福州水务联排联调中心、深水集团等大型工业、能源、水利、水务集团企业；EAP
            微服务平台：设备交互、数据接口、安全认证、KPI指标等微服务注册、授权、服务响应；区块链智能合约：自主知识产权区块链核心算法、智能合约、智能发布、区块链。
          </div>
          <!-- <div style="width:1264;height:603px;background-color:#ffffff;display: flex;align-items:center;text-align: center">
          </div> -->
          <img src="../assets/img/p11.png" />
        </div>
      </div>
    </section>
    <section class="qywenhua">
      <div class="am-container">
        <div class="apptit">
          <p class="entit">COMPANY CULTURE</p>
          <p class="tit">企业文化</p>
        </div>
        <div>
          <div class="qywh-list">
            <el-row :gutter="20">
              <el-col :span="8">
                <i>
                  <img src="~images/企业使命.png" />
                </i>
                <p class="tit">公司定位</p>
                <p class="note">智慧水务（数字水务）建设运营服务</p>
              </el-col>
              <el-col :span="8">
                <i>
                  <img src="~images/团队理念.png" />
                </i>
                <p class="tit">公司使命</p>
                <p class="note">
                  以客户服务为导向，通过智能物联设备、数据赋能平台及智慧运营管控服务，为水务行业上、下游客户提供更主动、更贴心的服务。
                </p>
              </el-col>
              <el-col :span="8">
                <i>
                  <img src="~images/企业理解.png" />
                </i>
                <p class="tit">经营理念</p>
                <p class="note">
                  与客户同发展；相信员工、产品和声誉是公司真正的财富
                </p>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </section>
    <section class="team-box">
      <div class="am-container">
        <div class="apptit">
          <p class="entit">COMPANY STYLE</p>
          <p class="tit">公司风采</p>
        </div>
        <div class="team-list">
          <ul id="lightgallery">
            <li>
              <a>
                <img src="../assets/img/p7.png" />
              </a>
            </li>
            <li>
              <a><img src="../assets/img/p8.png" /></a>
            </li>
            <li>
              <a><img src="../assets/img/p9.png" /></a>
            </li>
            <li>
              <a><img src="~images/组-49.png" /></a>
            </li>
            <li>
              <a><img src="~images/组-50.png" /></a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="certify">
      <div class="am-container">
        <div class="apptit">
          <p class="entit">CERTIFICATE</p>
          <p class="tit">资质证书</p>
        </div>
        <div class="zouma">
           <swiper ref="myswiper" :options="swiperOptions">
            <swiper-slide
              v-for="(item, index) in imagesbox"
              :key="index"
              class="slide"
            >
              <img :src="item.imgView" />
            </swiper-slide>
          </swiper>
            <!--          <div class="swiper-button-prev" @click="prev"></div>
            <div class="swiper-button-next" @click="next"></div> -->
          <!-- <ul class="container">
                        <li v-for="item in imagesbox" :key="item.id">
                            <img :src="item.imgView" >
                        </li>
                    </ul> -->
          <template>
            <el-carousel :interval="4000" type="card" height="800px">
              <el-carousel-item v-for="item in imagesbox" :key="item.id">
                <img :src="item.imgView" height="100%" width="100%" />
              </el-carousel-item>
            </el-carousel>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      map: {
        center: { lng: 119.276434, lat: 26.115194 },
        zoom: 17,
        show: true,
        dragging: true,
      },
      imagesbox: [
        {
          id: 0,
          imgView: require("../assets/ruanzhu/001.jpg"),
          href: "/Product",
          titl: "智慧物联 智慧水务",
          note: "领先的智慧水务系列产业和技术服务皆为高效安全的水管理",
        },
        {
          id: 1,
          imgView: require("../assets/ruanzhu/002.jpg"),
          href: "/Product",
          titl: "智慧物联 智慧水务",
          note: "领先的智慧水务系列产业和技术服务皆为高效安全的水管理",
        },
        {
          id: 2,
          imgView: require("../assets/ruanzhu/003.jpg"),
          href: "/Product",
          titl: "智慧物联 智慧水务",
          note: "领先的智慧水务系列产业和技术服务皆为高效安全的水管理",
        },
        {
          id: 3,
          imgView: require("../assets/ruanzhu/004.jpg"),
          href: "/Product",
          titl: "智慧物联 智慧水务",
          note: "领先的智慧水务系列产业和技术服务皆为高效安全的水管理",
        },
        {
          id: 4,
          imgView: require("../assets/ruanzhu/005.jpg"),
          href: "/Product",
          titl: "智慧物联 智慧水务",
          note: "领先的智慧水务系列产业和技术服务皆为高效安全的水管理",
        },
        {
          id: 5,
          imgView: require("../assets/ruanzhu/006.jpg"),
          href: "/Product",
          titl: "智慧物联 智慧水务",
          note: "领先的智慧水务系列产业和技术服务皆为高效安全的水管理",
        },
        {
          id: 6,
          imgView: require("../assets/ruanzhu/007.jpg"),
          href: "/Product",
          titl: "智慧物联 智慧水务",
          note: "领先的智慧水务系列产业和技术服务皆为高效安全的水管理",
        },
      ],
      swiperOptions: {
        slidesPerView: 7,
        spaceBetween: 0,
        loop: true,
        speed: 1000,
        autoplay: true,
        autoplayDisableOnInteraction: false,
        disableOnInteraction: true,
        observer: true,
        observeParents: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        mousewheelControl: true,
        debugger: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {},
  methods: {
    prev() {
      this.$refs.mySwiper.swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper.swiper.slideNext();
    },
    qq() {
      window.open("tencent://message/?uin=77673175&Menu=yes");
    },
    handler({ BMap, map }) {
      console.log(BMap, map);
      // 鼠标缩放
      map.enableScrollWheelZoom(true);
      // 点击事件获取经纬度
      map.addEventListener("click", function (e) {
        console.log(e.point.lng, e.point.lat);
      });
    },
  },
};

// var empile1 = new Empile($('.container'), {
//     autoplay: {
//         delay: 6000,
//         docHiddenOff: true,
//     },
//     waitForTransition: true,
//     isClickSlide: true,
//     navigation: {
//         nextEl: $('.demo1 .btn-right'),
//         prevEl: $('.demo1 .btn-left'),
//     },
//     on: {
//         //卡片切换时执行
//         slideChange: function () {
//             //设置'当前显示的是第[xxx]张'元素的文本内容
//             activeIndexEle.textContent = this.activeIndex + 1;
//         }
//     },
//     pagination: {
//         el: document.querySelector('.navigation'),
//         clickable: true,
//         bulletClass: 'dot',
//         bulletActiveClass: 'active',
//     },
//     css: function (coord, absCoord) {
//         var zIndex = 100 - absCoord,
//             opacity = Math.pow(.92, absCoord).toFixed(3),
//             scale = 'scale(' + Math.pow(.9, absCoord).toFixed(2) + ')',
//             translateX = 'translateX(' + 100 * coord + 'px)';

//         var transform = [translateX, scale].join(' ');
//         return {
//             zIndex: zIndex,
//             opacity: opacity,
//             transform: transform,
//         }
//     },

// });

// info(empile1);
</script>
<style lang='scss' scoped>
.call-box {
  padding: 80px 0;
}
.map-right {
  margin-top: 2%;
  .mapcol2 {
    margin-top: 60px;
    div {
      margin-top: 30px;
    }
  }
  .mapcol {
    margin-top: 60px;
    div {
      margin-top: 20px;
    }
    .el-button {
      margin-top: 10px;
    }
  }
}
.map {
  margin: 30px 0;
  width: 100%;
  height: 400px;
}
.trbaner {
  position: relative;
}
.trbaner img {
  max-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  border: 0;
}
.trbaner .foxj {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 22;
}
.am-container {
  position: relative;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  max-width: 1240px;
}
.foxj img {
  width: 100%;
}
.foxj .txt {
  position: absolute;
  text-align: left;
  left: 1.5rem;
  height: 100%;
  display: table-cell;
}
.foxj .txt .am-vertical-align-middle {
  position: relative;
  top: 30%;
}
.foxj .txt ::after {
  clear: both;
}
.foxj .txt .tit {
  font-size: 32px;
  margin-bottom: 15px;
  color: #fff;
}
.foxj .note {
  font-size: 16px;
  width: 800px;
  margin-bottom: 20px;
  line-height: 2;
  color: #fff;
}
.about-jj {
  margin: 80px 0 0 0;
  text-align: left;
}
.about-jj img {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  border: 0;
}
.am-container::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.am-container::before {
  content: " ";
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.about-jj .abjj-left {
  width: 60%;
  float: left;
}
.about-jj .abjj-left .tit {
  height: 47px;
  background: url(~images/rzyiot.png) 0 center no-repeat;
  font-size: 32px;
  line-height: 32px;
  margin-top: 38px;
  margin-bottom: 20px;
  text-align: left;
}
.about-jj .abjj-left .note {
  width: 622px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-indent: 2em;
  line-height: 40px;
  color: #6a6a6a;
  opacity: 1;
}

.abjj-left {
  .titl {
    margin: 20px;
    height: 25px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 25px;
    color: #30b9f3;
    opacity: 1;
    text-align: center;
    justify-items: center;
    align-content: center;
  }
}
.about-jj .abjj-right {
  width: 34%;
  float: right;
  margin-top: 100px;
}
.qywenhua {
  padding: 0 0 80px 0;
}
.apptit .entit {
  font-size: 16px;
  color: #999;
  text-transform: uppercase;
}
.apptit .tit {
  font-size: 32px;
  color: #4da9ff;
  line-height: 32px;
}
.qywh-list {
  margin: 40px;
  .note {
    height: 44px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 26px;
    color: #959595;
    opacity: 1;
    text-align: center;
  }
}
.qywenhua .am-avg-md-4 {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}
.qywenhua .am-avg-md-4 .tit {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 8px;
  color: #000;
}
.qywenhua .am-avg-md-4 li {
  display: inline-block;
  float: left;
  list-style: none;
  text-align: center;
  width: 25%;
  margin: 0 10px;
}
.qywenhua .qywh-list ::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.team-box {
  padding: 80px 0;
  background-color: #f5f5f5;
}
.team-list {
  margin-top: 40px;
}
// .team-box ul li img {
//     // display: none;
// }
.team-list ul li a {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s linear;
  cursor: pointer;
}
.team-list ul li {
  width: 22%;
  float: left;
  padding: 10px;
  height: 190px;
  list-style: none;
}
.team-list ul li:first-child {
  width: 50%;
  height: 400px;
}
:before :after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.certify {
  background-color: #fff;
  padding: 80px 0;
}
.zouma {
  margin: 50px 0;
  img {
    width: 100%;
  }
}
.contact-box {
  padding: 80px 0;
  background-color: #fff;
  .apptit {
    margin-bottom: 20px;
  }
  .contact-list {
    .left {
      display: inline-block;
      float: left;
      width: 40%;
      padding: 20px 0 0 100px;
    }
    .right {
      display: inline-block;
      width: 50%;
    }
    p {
      font-size: 24px;
      color: #000;
      margin: 5px;
      text-align: left;
    }
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.el-carousel__item img {
  overflow: hidden;
  max-height: 800px;
}
.hxjs {
  background-color: #f5f5f5;
  margin: 80px 0;
  padding: 80px 0;
  .note {
    margin: 100px 60px 60px 60px;
    width: 1145px;
    height: 114px;
    text-indent: 2em;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 40px;
    color: #6a6a6a;
    opacity: 1;
    text-align: left;
  }
}
</style>